import * as React from "react";
import { Link } from "gatsby";
import HeaderOne from "@/components/header-one";
import Footer from "@/components/footer";
import MenuContextProvider from "@/context/menu-context";
import Layout from "@/components/layout";
import { Helmet as Head } from "react-helmet";
import PageHeader from "@/components/page-header";
import favIcon from "@/images/favicon.ico";

// styles
const pageStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
  marginTop: "50px",
};
const headingStyles = {
  marginTop: 0,
  marginBottom: 30,
  maxWidth: 320,
};

const paragraphStyles = {
  marginBottom: 48,
};
// const codeStyles = {
//   color: "#8A6534",
//   padding: 4,
//   backgroundColor: "#FFF4DB",
//   fontSize: "1.25rem",
//   borderRadius: 4,
// };

// markup
const NotFoundPage = () => {
  return (
    <MenuContextProvider>
      <Layout PageTitle="404: Not Found">
        <HeaderOne />
        <Head key="favicon">
          <link rel="icon" href={favIcon} type="image/x-icon" />
          <title>No page found | Nifixeci</title>
          <meta name="description" content="404 | Page not found | Nifixeci" />
        </Head>
        <PageHeader title="404 Page Not Found" name="404 Error" />
        <main style={pageStyles}>
          <title>Not found</title>
          <h1 style={headingStyles}>Page not found</h1>
          <p style={paragraphStyles}>
            Sorry{" "}
            <span role="img" aria-label="Pensive emoji">
              😔
            </span>{" "}
            we couldn’t find what you were looking for.
            {/* <br /> */}
            {/* {process.env.NODE_ENV === "development" ? (
              <>
                <br />
                Try creating a page in{" "}
                <code style={codeStyles}>src/pages/</code>.
                <br />
              </>
            ) : null} */}
            {/* <br /> */}
            <Link to="/">Go home</Link>
          </p>
        </main>
        <Footer />
      </Layout>
    </MenuContextProvider>
  );
};

export default NotFoundPage;
